<template>
  <div class="h5Detail">
    <template v-if="item">
      <h1>{{ item ? item.title : "" }}</h1>
      <h4>{{ item ? item.createTime : "" }}</h4>
      <div style="width: 90%; margin: 10px auto">
        <NewVideo
          v-if="item && item.url"
          :sources="item.url"
          :idKay="'review'"
        ></NewVideo>
      </div>
      <div class="bz" v-if="item && item.memo">
        描述： {{ item ? (item ? item.memo : "") : "" }}
      </div>
      <!-- <div class="read-box">
        <div class="read">阅读量:{{ item.browseCount }}</div>
      </div> -->
      <!-- <div class="content" v-html="item ? item.content : ''"></div> -->
    </template>
    <div v-else class="not-data">暂无数据</div>
  </div>
</template>

<script>
import NewVideo from "@/components/newVideo.vue"; // 视频播放器
export default {
  components: { NewVideo },
  data() {
    return {
      type: 0, // 0查询列表直接访问第一条详情  1直接按文章详情查询
      item: null,
      leftList: [],
      nextItem: null,
      lastItem: null,
      nsIndex: 0,
      isns: true,
      // detail: {
      //   title: "9地60个项目：我国金融科技创新监管试点全面落地",
      //   date: "时间：2020-08-27 17:00:25",
      //   content: "我是富文本",
      // },
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      this.getDetails(this.$route.query.id);
    },
    async getDetails(id) {
      let res = await this.$api.second.getVideoDetial({
        id: id,
      });
      console.log("查询详情", res);
      if (res.status == 200) {
        this.item = res.data;
      }
    },
  },
};
</script>

<style lang="scss">
.not-page {
  color: #909399 !important;
}
.not-data {
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.h5Detail {
  padding-top: 65px;
  position: relative;

  .bz {
    padding: 20px;
    background-color: #ece8e8;
    font-size: 14px;
  }
  h1 {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  h4 {
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 17px;
  }
}
</style>
